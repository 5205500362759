<template>
  <div class="flex flex-col w-full gap-1">
    <label class="font-['Red Hat Text'] text-[16px] text-basicText">
      {{ label }}
    </label>
    <div
      class="flex flex-row justify-center bg-[#F1EFEF] py-4 px-2 border-l-[2px] rounded-sm border-l-primary focus:ring-2 focus:ring-primary outline-none focus:shadow-sm hover:shadow-sm hover:border-l-3 hover:border-primary"
    >
      <div class="flex flex-col w-full">
        <input
          :type="inputType"
          :placeholder="placeholder"
          class="bg-[#F1EFEF] w-full outline-none"
          @input="onChange"
        />
      </div>
      <div
        v-if="type === 'password'"
        class="flex flex-col items-center justify-center mr-1"
      >
        <UIcon
          :name="computedIcon"
          class="cursor-pointer h-5 w-5"
          @click="toggleType"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(["update:model-value"]);

function onChange({ target }: any) {
  emit("update:model-value", target.value);
}

const props = withDefaults(
  defineProps<{
    type: "text" | "password";
    modelValue: string;
    placeholder: string;
    label: string;
  }>(),
  {
    type: "text",
  },
);

const inputType = ref(props.type);

const computedIcon = computed(() => {
  return inputType.value === "password"
    ? "i-heroicons-eye-slash"
    : "i-heroicons-eye";
});

const toggleType = () => {
  inputType.value = inputType.value === "password" ? "text" : "password";
};
</script>

<style scoped>
input {
  @apply transition-all duration-300;
}
</style>
